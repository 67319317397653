import { default as editdZmhIryHKTMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/[username]/[boardSlug]/edit.vue?macro=true";
import { default as indexRzwxHYFTYeMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/[username]/[boardSlug]/index.vue?macro=true";
import { default as index7vEY1m9P71Meta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/[username]/index.vue?macro=true";
import { default as aboutbMgqJjbiluMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/about.vue?macro=true";
import { default as indexFUqdrrX6ghMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/boards/index.vue?macro=true";
import { default as newrFpQfDZoFfMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/boards/new.vue?macro=true";
import { default as how_45too0vJz7T8csMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/how-to.vue?macro=true";
import { default as indexBBwNLQMIPIMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/index.vue?macro=true";
import { default as logoutJ6d2NfPmM8Meta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/logout.vue?macro=true";
import { default as notificationsRLJoJeYhJmMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/notifications.vue?macro=true";
import { default as bookmarksAlkPlk8HteMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/bookmarks.vue?macro=true";
import { default as edit4uklDAsRSzMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/edit.vue?macro=true";
import { default as editvRexApH6SjMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/password/edit.vue?macro=true";
import { default as newHJWQK8sGZLMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/password/new.vue?macro=true";
import { default as sign_45inBazQhgtujkMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/sign-in.vue?macro=true";
import { default as sign_45up99AFH94hHuMeta } from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/sign-up.vue?macro=true";
export default [
  {
    name: editdZmhIryHKTMeta?.name ?? "username-boardSlug-edit",
    path: editdZmhIryHKTMeta?.path ?? "/:username()/:boardSlug()/edit",
    meta: editdZmhIryHKTMeta || {},
    alias: editdZmhIryHKTMeta?.alias || [],
    redirect: editdZmhIryHKTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/[username]/[boardSlug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexRzwxHYFTYeMeta?.name ?? "username-boardSlug",
    path: indexRzwxHYFTYeMeta?.path ?? "/:username()/:boardSlug()",
    meta: indexRzwxHYFTYeMeta || {},
    alias: indexRzwxHYFTYeMeta?.alias || [],
    redirect: indexRzwxHYFTYeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/[username]/[boardSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: index7vEY1m9P71Meta?.name ?? "username",
    path: index7vEY1m9P71Meta?.path ?? "/:username()",
    meta: index7vEY1m9P71Meta || {},
    alias: index7vEY1m9P71Meta?.alias || [],
    redirect: index7vEY1m9P71Meta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/[username]/index.vue").then(m => m.default || m)
  },
  {
    name: aboutbMgqJjbiluMeta?.name ?? "about",
    path: aboutbMgqJjbiluMeta?.path ?? "/about",
    meta: aboutbMgqJjbiluMeta || {},
    alias: aboutbMgqJjbiluMeta?.alias || [],
    redirect: aboutbMgqJjbiluMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexFUqdrrX6ghMeta?.name ?? "boards",
    path: indexFUqdrrX6ghMeta?.path ?? "/boards",
    meta: indexFUqdrrX6ghMeta || {},
    alias: indexFUqdrrX6ghMeta?.alias || [],
    redirect: indexFUqdrrX6ghMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/boards/index.vue").then(m => m.default || m)
  },
  {
    name: newrFpQfDZoFfMeta?.name ?? "boards-new",
    path: newrFpQfDZoFfMeta?.path ?? "/boards/new",
    meta: newrFpQfDZoFfMeta || {},
    alias: newrFpQfDZoFfMeta?.alias || [],
    redirect: newrFpQfDZoFfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/boards/new.vue").then(m => m.default || m)
  },
  {
    name: how_45too0vJz7T8csMeta?.name ?? "how-to",
    path: how_45too0vJz7T8csMeta?.path ?? "/how-to",
    meta: how_45too0vJz7T8csMeta || {},
    alias: how_45too0vJz7T8csMeta?.alias || [],
    redirect: how_45too0vJz7T8csMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/how-to.vue").then(m => m.default || m)
  },
  {
    name: indexBBwNLQMIPIMeta?.name ?? "index",
    path: indexBBwNLQMIPIMeta?.path ?? "/",
    meta: indexBBwNLQMIPIMeta || {},
    alias: indexBBwNLQMIPIMeta?.alias || [],
    redirect: indexBBwNLQMIPIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logoutJ6d2NfPmM8Meta?.name ?? "logout",
    path: logoutJ6d2NfPmM8Meta?.path ?? "/logout",
    meta: logoutJ6d2NfPmM8Meta || {},
    alias: logoutJ6d2NfPmM8Meta?.alias || [],
    redirect: logoutJ6d2NfPmM8Meta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: notificationsRLJoJeYhJmMeta?.name ?? "notifications",
    path: notificationsRLJoJeYhJmMeta?.path ?? "/notifications",
    meta: notificationsRLJoJeYhJmMeta || {},
    alias: notificationsRLJoJeYhJmMeta?.alias || [],
    redirect: notificationsRLJoJeYhJmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: bookmarksAlkPlk8HteMeta?.name ?? "users-bookmarks",
    path: bookmarksAlkPlk8HteMeta?.path ?? "/users/bookmarks",
    meta: bookmarksAlkPlk8HteMeta || {},
    alias: bookmarksAlkPlk8HteMeta?.alias || [],
    redirect: bookmarksAlkPlk8HteMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/bookmarks.vue").then(m => m.default || m)
  },
  {
    name: edit4uklDAsRSzMeta?.name ?? "users-edit",
    path: edit4uklDAsRSzMeta?.path ?? "/users/edit",
    meta: edit4uklDAsRSzMeta || {},
    alias: edit4uklDAsRSzMeta?.alias || [],
    redirect: edit4uklDAsRSzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/edit.vue").then(m => m.default || m)
  },
  {
    name: editvRexApH6SjMeta?.name ?? "users-password-edit",
    path: editvRexApH6SjMeta?.path ?? "/users/password/edit",
    meta: editvRexApH6SjMeta || {},
    alias: editvRexApH6SjMeta?.alias || [],
    redirect: editvRexApH6SjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/password/edit.vue").then(m => m.default || m)
  },
  {
    name: newHJWQK8sGZLMeta?.name ?? "users-password-new",
    path: newHJWQK8sGZLMeta?.path ?? "/users/password/new",
    meta: newHJWQK8sGZLMeta || {},
    alias: newHJWQK8sGZLMeta?.alias || [],
    redirect: newHJWQK8sGZLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/password/new.vue").then(m => m.default || m)
  },
  {
    name: sign_45inBazQhgtujkMeta?.name ?? "users-sign-in",
    path: sign_45inBazQhgtujkMeta?.path ?? "/users/sign-in",
    meta: sign_45inBazQhgtujkMeta || {},
    alias: sign_45inBazQhgtujkMeta?.alias || [],
    redirect: sign_45inBazQhgtujkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45up99AFH94hHuMeta?.name ?? "users-sign-up",
    path: sign_45up99AFH94hHuMeta?.path ?? "/users/sign-up",
    meta: sign_45up99AFH94hHuMeta || {},
    alias: sign_45up99AFH94hHuMeta?.alias || [],
    redirect: sign_45up99AFH94hHuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/pages/users/sign-up.vue").then(m => m.default || m)
  }
]