import VueGtag from 'vue-gtag'
import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp) => {
  const publicConf = useRuntimeConfig().public

  nuxtApp.vueApp.use(VueGtag, {
    property: { id: publicConf.gtagId },
    appName: publicConf.name,
    pageTrackerTemplate: to => to.fullPath,
  }, nuxtApp.vueApp.router)
})
