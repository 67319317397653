import { defineNuxtRouteMiddleware, useCookie, useRuntimeConfig } from 'nuxt/app'
import { useAuthStore } from '~/stores/auth'

/**
 * Logout middleware
 */
export default defineNuxtRouteMiddleware(async () => {
  const token = useCookie(useRuntimeConfig().public.authCookieName).value
  if (token && ! useAuthStore().user) {
    useAuthStore().token = token
    await useAuthStore().getUser()
  }
})
