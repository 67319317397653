import revive_payload_client_4sVQNw7RlN from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_1UohGbtF8v from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import clipboard_un1pNMmI0D from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/plugins/clipboard.js";
import eventHub_YIW5hN5hb3 from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/plugins/eventHub.js";
import filters_znakZuvLqA from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/plugins/filters.js";
import gtag_client_R70iZRv6jK from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/plugins/gtag.client.js";
import notify_Oxf81SlxaN from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/plugins/notify.js";
import pinia_log_iJk1efWLt2 from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/plugins/pinia-log.js";
import recaptcha_W4US4xmBV6 from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/plugins/recaptcha.js";
import screen_AYzqh1bpFN from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/plugins/screen.js";
import sentry_PZVGm6LH3G from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/plugins/sentry.js";
import tooltip_07WqZjDZUO from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/plugins/tooltip.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_1UohGbtF8v,
  clipboard_un1pNMmI0D,
  eventHub_YIW5hN5hb3,
  filters_znakZuvLqA,
  gtag_client_R70iZRv6jK,
  notify_Oxf81SlxaN,
  pinia_log_iJk1efWLt2,
  recaptcha_W4US4xmBV6,
  screen_AYzqh1bpFN,
  sentry_PZVGm6LH3G,
  tooltip_07WqZjDZUO
]