import * as Sentry from '@sentry/vue'
import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp
  const { sentryDsn, stage } = useRuntimeConfig().public

  if (! sentryDsn) { return }

  Sentry.init({
    app: [vueApp],
    dsn: sentryDsn,
    environment: stage,
    beforeSend (event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint })
      }
      // Continue sending to Sentry
      return event
    },
  })

  return {
    provide: {
      sentry: Sentry,
    },
  }
})
