import pluralize from 'pluralize'
import dayjs from 'dayjs'
import capitalize from 'lodash/capitalize'
import { defineNuxtPlugin } from '#imports'

function time (value, format = 'hh:mmA') {
  return dayjs(`01/01/2000 ${value}`).format(format)
}

function date (value, format = 'M/D/YY @ hh:mmA') {
  return dayjs(value).format(format)
}

function datetime (value) {
  if (typeof value === 'undefined') {
    return 'undefined'
  }

  return dayjs(value).format('MMM DD, YYYY [at] h:mm a')
}

function fromNow (value) {
  return dayjs(value).fromNow(true)
}

function initials (value) {
  if (! value) { return '' }
  const hasTokens = value.includes(' ')
  return value.substring(0, hasTokens ? 1 : 2) + (hasTokens ? value.charAt(value.lastIndexOf(' ') + 1) : '')
}

function bytes (bytes, decimals, kib = true, maxunit) {
  if (bytes === 0) { return '0 Bytes' }
  if (isNaN(parseFloat(bytes)) && ! isFinite(bytes)) { return '' }
  const k = kib ? 1024 : 1000
  const dm = (decimals !== null && ! isNaN(decimals) && decimals >= 0) ? decimals : 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB']
  let i = Math.floor(Math.log(bytes) / Math.log(k))
  if (maxunit !== undefined) {
    const index = sizes.indexOf(maxunit)
    if (index !== -1) { i = index }
  }

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

function age (date) {
  if (date) {
    const now = new Date()
    date = new Date(date)
    const age = now.getFullYear() - date.getFullYear() - ((now.getMonth() > date.getMonth() || (now.getMonth() === date.getDate())) ? 0 : 1)

    return age < 1 ? '< 1' : age
  }
}

function cite (paper) {
  let mainAuthor = ''
  let year = ''
  let pub = ''

  if (paper.authors.length > 0) {
    mainAuthor = paper.authors[0].family_name
    if (mainAuthor.length > 50) {
      mainAuthor = `${mainAuthor.substring(0, 50)}...`
    }
    if (paper.published_at !== null) {
      year = new Date(paper.published_at).getFullYear()
    }
    if (paper.publication !== null) {
      pub = paper.publication
      if (pub.length > 50) {
        pub = `${pub.substring(0, 50)}...`
      }
    }
    const joinedCitation = [year, mainAuthor, pub].filter(v => v).join(', ')

    if (pub !== '' || year !== '') {
      return `[${joinedCitation}]`
    }
  }
}

function stripTags (value) {
  return value.replace(/(<([^>]+)>)/gi, '')
}

function truncate (string, length, truncate) {
  if (typeof truncate === 'undefined') {
    truncate = 'true'
  }
  if (truncate && string && string.length > length) {
    let s = string.substring(0, length)
    const openLinkCount = (s.match('<a target') || []).length
    const closeLinkCount = (s.match('</a>') || []).length
    if (openLinkCount !== closeLinkCount) {
      s = s.substring(0, string.lastIndexOf('<a target'))
    }
    return `${s}...`
  } else {
    return string
  }
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      f: {
        pluralize,
        capitalize,
        time,
        date,
        datetime,
        fromNow,
        initials,
        bytes,
        age,
        cite,
        stripTags,
        truncate,
      },
    },
  }
})
