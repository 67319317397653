import { showError, useRuntimeConfig } from 'nuxt/app'
import type { FetchOptions } from 'ofetch'
import { $fetch } from 'ofetch'
import merge from 'lodash/merge'
import qs from 'qs'
import useToast from './useToast'
import { useAuthStore } from '~/stores/auth'

export const useApi = async (url: string, options: FetchOptions = {}) => {
  const config = useRuntimeConfig()
  const authStore = useAuthStore()

  if (options.query) {
    url += `?${qs.stringify(options.query, {
     arrayFormat: 'brackets',
     filter: (prefix: any, value: any) => value !== null ? value : undefined,
   })}`
    delete options.query
  }

  try {
    return await $fetch(url, merge({
      baseURL: config.public.apiUrl,
      headers: {
        accept: 'application/json',
        Authorization: authStore.token ? `Bearer ${authStore.token}` : '',
      },
    }, options))
  } catch (e: any) {
    // Show 404 error page on 404 API response
    if (e?.response?.status === 404) {
      showError({ statusCode: 404, message: e.message })
    }
    if (e?.response?.status === 401) {
      authStore.signOut()
    }
    // Show toast message
    const errorMessage = e?.response?._data?.message
    if (errorMessage) {
      useToast().addToast({ title: 'Error', text: errorMessage, type: 'error' })
    }
    throw e
  }
}
