import validate from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/middleware/auth.global.js";
import manifest_45route_45rule from "/home/runner/work/projectcredo-frontend/projectcredo-frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/middleware/authenticated.js"),
  guest: () => import("/home/runner/work/projectcredo-frontend/projectcredo-frontend/middleware/guest.js")
}