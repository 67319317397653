<template>
  <NuxtLayout>
    <div v-if="error" class="container mx-auto my-20 text-center text-lg">
      <h1>{{ error.statusCode }}</h1>
      <h1 v-if="error.statusCode === 404">
        Page not found
      </h1>
      <h1 v-else>
        An error occurred
      </h1>
      <NuxtLink to="/" class="mt-5 inline-block">
        Go to Home page
      </NuxtLink>
    </div>
  </NuxtLayout>
</template>

<script>
import { useError } from 'nuxt/app'

export default {
  data: () => ({
    error: useError(),
  }),
}
</script>
