import { defineStore } from 'pinia'
import { useApi } from '~/composables/useApi'
import { useCookie, useRuntimeConfig } from '#app'

function setCookie (context, token) {
  const cookie = useCookie(useRuntimeConfig().public.authCookieName, { path: '/', maxAge: 60 * 60 * 24 * 30 })
  cookie.value = token
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null,
    user: null,
    loading: false,
  }),

  actions: {
    async signIn (form) {
      this.loading = true
      try {
        const res = await useApi('/api/login', { method: 'post', body: form })
        setCookie(this, res.token)
        this.user = res.user
        this.token = res.token
      } finally {
        this.loading = false
      }
    },

    async signInSocial ({ type, form }) {
      this.loading = true
      const res = await useApi(`/api/login/${type}`, { method: 'post', body: form })
      setCookie(this, res.token)
      this.loading = false
      this.user = res.user
      this.token = res.token
    },

    async signUp (form) {
      this.loading = true
      try {
        const res = await useApi('/api/register', { method: 'post', body: form })
        setCookie(this, res.token)
        this.user = res.user
        this.token = res.token
      } finally {
        this.loading = false
      }
    },

    async editPassword (form) {
      this.loading = true
      try {
        const res = await useApi('/api/reset-password', { method: 'post', body: form })
        this.user = res.user
        this.token = res.token
      } finally {
        this.loading = false
      }
    },

    signOut () {
      const cookie = useCookie(useRuntimeConfig().public.authCookieName)
      cookie.value = null
      this.token = null
      this.user = null
    },

    async getUser () {
      this.loading = true
      try {
        this.user = await useApi('/api/profile')
      } finally {
        this.loading = false
      }
    },
  },
})
